import { lazy, Suspense, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

import { UseGetMeReturnType, UserContext } from '../../contexts/user-context';
import {
    ClassValue,
    GLOBAL_PM,
    ProgressMonitor,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
    useSetTimeout,
} from '../basic';
import { LoadingSuspense } from './loading-suspense';
import { LoadingPane } from '../common/panes/loading-pane';
import { ErrorPane } from '../common/panes/error-pane';
import usersConnector from '../../utils/connectors/users-connector';
import { isAuthenticated } from 'src/utils/connector';
import { useHasAnyPermissions, useHasPermission } from 'src/contexts/user-permission';
import { SettingsPermissions } from 'src/settings/permissions/permissions';
import { USERS_AND_GROUPS_ROUTES } from 'src/settings/users';
import { CONTEXTUAL_VARIABLES_PATH } from 'src/settings/contextual-variables';
import { VISUAL_IDENTITY_PATH } from 'src/settings/visual-identity';
import { UseGetMePermissionsReturnType, UserPermissionsContext } from 'src/contexts/user-permissions-context';
import { MODULES } from '../application/modules';
import { ApplicationConfigurationsContext } from '../../contexts/application-configurations';
import { useApplicationConfigurations } from '../../hooks/use-application-configuration';
import { ChangePassword } from '../common/change-password/views/change-password';
import { Environment } from 'src/utils/environment';

import './authenticated-apps-router.less';

const FORCE_ERROR = false;
const FORCE_LOADING = false;
const FORCE_SUSPENSE_LOADING = false;

const LazyExplorationRouter = lazy(() => import('../../exploration/exploration-router'));
const LazyPreparationRouter = lazy(() => import('../../preparation/preparation-router'));
const LazyCollectRouter = lazy(() => import('../../collect/utils/routing/collect-router'));
const LazySettingsRouter = lazy(() => import('../../settings/settings-router'));
const LazyAdminRouter = lazy(() => import('../../administration/utils/routing/admin-router'));
const LazyProceoRouter = lazy(() => import('../../proceo/proceo-router'));

const RETRY_TIMER = 1000 * 30 + Math.floor(Math.random() * 10000);

const messages = defineMessages({
    loadingUserProfile: {
        id: 'authenticated-apps-router.LoadingUserProfile',
        defaultMessage: 'Loading user profile',
    },
    errorLoadingUserProfile: {
        id: 'authenticated-apps-router.ErrorLoadingUserProfile',
        defaultMessage: '<p><b>Internal server error</b></p><p>Error while loading user profile.</p><p>Please retry in few minutes.</p>',
    },
    fetchingPermissions: {
        id: 'settings.settings-router.FetchingPermissions',
        defaultMessage: 'Fetching permissions',
    },
});

export function AuthenticatedAppsRouter() {
    const classNames = useClassNames('common-authenticated-apps-router');

    const notifications = useArgNotifications();
    const [applicationConfigurations] = useApplicationConfigurations();

    const [currentUser, progressMonitor, error] = useMemoAsync<UseGetMeReturnType>(async (progressMonitor: ProgressMonitor) => {
        try {
            const user = await usersConnector.myUserDetails(progressMonitor);

            const ret: UseGetMeReturnType = {
                me: user,
            };

            return ret;
        } catch (error: any) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            throw error;
        } finally {
            progressMonitor.done();
        }
    }, []);

    const [userPermissionsContext, userPermissionsProgressMonitor, errorUserPermissions] = useMemoAsync(async (progressMonitor) => {
        // Work around to check is the user is currently authenticated. We don't want to display a snackError
        const auth = isAuthenticated();

        if (!auth) {
            return;
        }

        try {
            const permissions = await usersConnector.getAllMyUserPermissions(progressMonitor);

            const ret: UseGetMePermissionsReturnType = {
                permissions: permissions,
            };

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.fetchingPermissions }, error as Error);
            throw error;
        }
    }, [notifications], messages.fetchingPermissions, 1, GLOBAL_PM);

    const startTimer = useSetTimeout(RETRY_TIMER);

    useEffect(() => {
        if (!error) {
            return;
        }

        console.log('Waiting for', RETRY_TIMER, 'ms');

        async function test() {
            try {
                await usersConnector.myUserDetails(progressMonitor);
            } catch (x) {
                console.log('Retry for', RETRY_TIMER, 'ms');
                startTimer(test);

                return;
            }

            console.log('Ready !!!');

            document.location.reload();
        }

        startTimer(test);
    }, [error]);

    if (FORCE_ERROR || error || errorUserPermissions) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    message={messages.errorLoadingUserProfile}
                    error={error}
                    className={classNames('&-error')}
                    size='large'
                />
            </div>
        );
    }

    if (FORCE_LOADING || currentUser === undefined || !userPermissionsContext || progressMonitor?.isRunning || userPermissionsProgressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={progressMonitor}
                    className={classNames('&-loading')}
                    size='large'
                />
            </div>
        );
    }

    return (
        <ApplicationConfigurationsContext.Provider value={applicationConfigurations}>
            <UserContext.Provider value={currentUser}>
                <UserPermissionsContext.Provider value={userPermissionsContext}>
                    <Routes>
                        <Route
                            path='exploration'
                            element={<Navigate to='./folders' replace={true} />}
                        />
                        <Route
                            path='exploration/*'
                            element={(
                                <ExplorationApp
                                    className={classNames('&', 'route')}
                                />
                            )}
                        />
                        <Route
                            path='preparation'
                            element={<Navigate to='./folders' replace={true} />}
                        />
                        <Route
                            path='preparation/*'
                            element={(
                                <PreparationApp
                                    className={classNames('&', 'route')}
                                />
                            )}
                        />
                        <Route
                            path='settings'
                            element={<AutomaticRedirection />}
                        />
                        <Route
                            path='settings/*'
                            element={
                                <SettingsApp
                                    className={classNames('&', 'route')}
                                />
                            }
                        />
                        <Route
                            path='admin'
                            element={<Navigate to='./environments' />}
                        />
                        <Route
                            path='admin/*'
                            element={(
                                <AdminApp
                                    className={classNames('&', 'route')}
                                />
                            )}
                        />
                        <Route
                            path='collect'
                            element={<Navigate to='./folders' replace={true} />}
                        />
                        <Route
                            path='collect/*'
                            element={(
                                <CollectApp
                                    className={classNames('&', 'route')}
                                />
                            )}
                        />
                        <Route
                            path='proceo'
                            element={<Navigate to='./home' replace={true} />}
                        />
                        <Route
                            path='proceo/*'
                            element={(
                                <ProceoApp
                                    className={classNames('&', 'route')}
                                />
                            )}
                        />

                        {/* Change password */}
                        {Environment.apiOAuth &&
                            <Route
                                path='me/change-password'
                                element={<ChangePassword user={currentUser.me} />}
                            />
                        }
                    </Routes>
                </UserPermissionsContext.Provider>
            </UserContext.Provider>
        </ApplicationConfigurationsContext.Provider>
    );
}

function AutomaticRedirection() {
    const hasUsersAndGroupsAccess = useHasAnyPermissions<SettingsPermissions>('admin.user.access', 'admin.user.group.access');
    const hasVisualIdentityAccess = useHasPermission<SettingsPermissions>('admin.visual.identity.management');
    const hasWebhookAccess = useHasAnyPermissions<SettingsPermissions>('admin.webhook.access');
    const hasRolesAccess = useHasPermission<SettingsPermissions>('admin.user.role.access');
    const hasContextualVariableAccess = useHasAnyPermissions<SettingsPermissions>('admin.contextual.variable.management', 'admin.contextual.variable.edition');

    if (hasUsersAndGroupsAccess) {
        return <Navigate to={USERS_AND_GROUPS_ROUTES} />;
    }
    if (MODULES.DataExploration.enabled && hasContextualVariableAccess) {
        return <Navigate to={CONTEXTUAL_VARIABLES_PATH} />;
    }
    if (hasVisualIdentityAccess) {
        return <Navigate to={VISUAL_IDENTITY_PATH} />;
    }
    if (hasWebhookAccess) {
        return <Navigate to='/settings/extensions/webhooks' />;
    }
    if (hasRolesAccess) {
        return <Navigate to='/settings/roles/admin' />;
    }

    return null;

    // TODO: Take into account la section ##Modules##
}

interface AppProps {
    className?: ClassValue;
}

function ExplorationApp(props: AppProps) {
    const { className } = props;

    if (!MODULES.DataExploration.enabled) {
        return null;
    }

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyExplorationRouter
                className={className}
            />
        </Suspense>
    );
}

function ProceoApp(props: AppProps) {
    const { className } = props;

    if (!MODULES.Proceo.enabled) {
        return null;
    }
    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyProceoRouter
                className={className}
            />
        </Suspense>
    );
}

function PreparationApp(props: AppProps) {
    const { className } = props;

    if (!MODULES.DataPreparation.enabled) {
        return null;
    }
    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyPreparationRouter
                className={className}
            />
        </Suspense>
    );
}

function CollectApp(props: AppProps) {
    const { className } = props;
    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    if (!MODULES.DataCollect.enabled) {
        return null;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyCollectRouter
                className={className}
            />
        </Suspense>
    );
}

function AdminApp(props: AppProps) {
    const { className } = props;

    if (!MODULES.Administration.enabled) {
        return null;
    }

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyAdminRouter
                className={className}
            />
        </Suspense>
    );
}

function SettingsApp(props: AppProps) {
    const { className } = props;

    if (!MODULES.Settings.enabled) {
        return null;
    }

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazySettingsRouter
                className={className}
            />
        </Suspense>
    );
}

import type { PublicUser, User, Users } from '../../model/user';
import { Collaborator, CollaboratorRole, CollaboratorType } from 'src/model/collaborator';
import { ArgUserId, ProgressMonitor } from '../../components/basic';
import { ConnectorRequestInit } from '../connector';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { getAdministrationApi } from './api-url';
import { AddUserDTO, EditUserDTO } from 'src/settings/models/dtoApi';

export class UsersAdminConnector extends BaseConnector {
    private static instance: UsersAdminConnector;

    static getInstance(): UsersAdminConnector {
        if (!UsersAdminConnector.instance) {
            UsersAdminConnector.instance = new UsersAdminConnector('administration.users', getAdministrationApi());
        }

        return UsersAdminConnector.instance;
    }


    async getUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<User> {
        const url = `/users/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }

    async getUsers(
        deleted: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<User[]> {
        const url = '/users';
        const options = {
            method: 'GET',
            params: {
                deleted,
            },
            verifyJSONResponse: true,
        };
        const response: Users = await this.request(url, options, progressMonitor);

        const users = response.users?.map(mapUser) || [];

        return users;
    }

    async addUser(
        addUserPayload: AddUserDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ id: ArgUserId }> {
        const url = '/users';
        const options = {
            method: 'POST',
            verifyJSONResponse: true,
            json: {
                ...addUserPayload,
                fullName: addUserPayload.displayName,
            },
        };
        const newUserId = await this.request(url, options, progressMonitor);

        return newUserId;
    }

    async deleteUser(
        userId: ArgUserId,
        deletePermanently: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'DELETE',
            params: {
                deletePermanently,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async editUser(
        editUserPayload: EditUserDTO,
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'PUT',
            json: {
                ...editUserPayload,
                fullName: editUserPayload.displayName,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async resetPassword(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ password: string }> {
        const url = `/users/${encodeURIComponent(userId)}/password`;
        const options = {
            method: 'POST',
        };
        const newPassword = await this.request(url, options, progressMonitor);

        return newPassword;
    }

    async changePassword(
        userId: ArgUserId,
        password: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ password: string }> {
        const url = `/users/${encodeURIComponent(userId)}/password`;
        const options = {
            method: 'PUT',
            json: {
                password,
            },
        };

        const newPassword = await this.request(url, options, progressMonitor);

        return newPassword;
    }

    async getPublicUsers(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<PublicUser[]> {
        const url = '/users/public';

        const options: ConnectorRequestInit = {
            params: {
                q: search,
                deleted,
            },
            verifyJSONResponse: true,
        };

        const userResults = await this.request(url, options, progressMonitor);

        const ret = userResults.users?.map(mapUser) || [];

        return ret;
    }

    async getCollaborators(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Collaborator[]> {
        const users = await this.getPublicUsers(search, deleted, progressMonitor); //Temporary while groups are not handled by API

        return users.map(user => {
            const collaborator: Collaborator = {
                identityId: user.id,
                name: user.displayName || '',
                type: CollaboratorType.User,
                user: user,
                role: CollaboratorRole.Reader,
            };

            return collaborator;
        });
    }

    async getPublicUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<PublicUser> {
        const url = `/users/public/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }
}

export default UsersAdminConnector.getInstance();

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

